import React, { memo, useState } from "react";
import FSApplicationList from "../FSApplicationList";
import Loading from '../../../components/Loading';
import FSApplicationModal from "../FSApplicationModal";
import FSApplicationDialog from "../FSApplicationDialog";
import FSApplicationFilter from "../FSApplicationFilter";
import * as appConstant from "../../../constants/appConstant"
import Button from 'react-bootstrap/Button';
import PageHeader from "../../../components/PageHeader";
import Pagination from "react-js-pagination";
import "./styles.scss";
import axios from "axios";

function Wrapper(props) {

  const initState = {
    "id": null,
    "name": null,
    "description": null,
    "isActive": true,
    "supportedDomains": null,
    "secretkey": null,
    "isDeleted": false,
    "createdOn": "",
  }

  let initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE,
    "applicationName": null,
    "isActive": null,
  };

  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editData, setEditData] = useState(initState);
  const [modalMode, setModalMode] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [filterParamsData, setFilterParamsData] = useState(initMock);
  const [activePage, setActivePage] = useState(1);
  const [baseUrl, setBaseUrl] = useState();
  const [api, setApi] = useState();
  let url = "https://preprodfileservice.petronas.com/api/v1/view/image/68866615-f90e-44f2-b4fb-813582c7a414-response.png";
  const [appId, setAppId] = useState();

  const [uploadBaseUrl, setUploadBaseUrl] = useState();
  const [apiUpload, setApiUpload] = useState();
  const [userUpload, setUsernamUpload] = useState();
  const [urlUpload, setUrlUpload] = useState(null);
  const [appIdUpload, setAppIdUpload] = useState();
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const [isConvertVideo, setIsConvertVideo] = useState("");
  const [folderID, setFolderID] = useState("");
  const [lifetime, setLifetime] = useState("");

  const setFilter = (params) => {
    setFilterParamsData(params);
  }

  const onChangeBaseUrlUpload = (e) => {
    setUploadBaseUrl(e.target.value)
  };

  const onChangeNameUpload = (e) => {
    setName(e.target.value)
  };

  const onChangeDescriptionUpload = (e) => {
    setDescription(e.target.value)
  };

  const onChangeTagUpload = (e) => {
    setTag(e.target.value)
  };

  const onChangeIsConvertVideoUpload = (e) => {
    setIsConvertVideo(e.target.value)
  };

  const onChangeFolderIDUpload = (e) => {
    setFolderID(e.target.value)
  };

  const onChangeLifetimeUpload = (e) => {
    setLifetime(e.target.value)
  };

  const onChangeApiUpload = (e) => {
    setApiUpload(e.target.value)
  };

  const onChangeAppIdUpload = (e) => {
    setAppIdUpload(e.target.value)
  };

  const onChangeUsernameUpload = (e) => {
    setUsernamUpload(e.target.value)
  };

  const onChangeBaseUrl = (e) => {
    setBaseUrl(e.target.value)
  };

  const onChangeApi = (e) => {
    setApi(e.target.value)
  };

  const onChangeAppId = (e) => {
    setAppId(e.target.value)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("files", file);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("tags", tag);
    formData.append("folderId", folderID);
    if (!lifetime && lifetime !== "") {
      formData.append("LifeTime", lifetime);
    }

    if (!isConvertVideo && isConvertVideo !== "") {
      formData.append("IsConvertVideo", isConvertVideo);
    }

    const http = axios.create({
      baseURL: uploadBaseUrl,
    })
    http.defaults.headers = {
      "content-type": "multipart/form-data",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'ApplicationID': appIdUpload
    };
    return http.post(apiUpload, formData)
      .then((res) => {
        alert(res.data.Url);
        document.querySelector(".uploadResult").value = res.data.Url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function ViewFSImage() {
    const http = axios.create({
      baseURL: baseUrl,
    })
    http.defaults.headers = {
      'ApplicationID': appId,
      'Access-Control-Allow-Origin': 'https://dev.notificationservice.petronas.com',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS'
    };
    return http.get(api)
      .then((res) => {
        // alert("res." + res)
        url = baseUrl + api
        alert("url." + url)
        document.querySelector(".image").src = url;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // const onPageChange = (pageNumber) => {
  //   setActivePage(pageNumber);
  //   const pageIndex = pageNumber - 1;
  //   const draft = filterParamsData;
  //   draft.pageIndex = pageIndex;
  //   props.onInitApplications(draft)
  // }

  // const onEditData = (id) => {
  //   const item = props.applicationsData.data.find(i => i.id === id);
  //   setEditData(item);
  //   setModalMode('EDIT');
  //   setEditModalStatus(true);
  // }

  // const onUpdateData = async () => {
  //   await props.onUpdateApplication(initMock, editData);
  //   setEditModalStatus(false);
  // }

  // const onOpenCreateModal = async () => {
  //   setEditData(initState);
  //   setModalMode('ADD');
  //   setEditModalStatus(true);
  // }

  // const onCreateData = async () => {
  //   await props.onCreateApplication(initMock, editData);
  //   setEditModalStatus(false);
  // }

  // const openDeleteModal = (k) => {
  //   setIdDelete(k);
  //   setDialogStatus(true);
  // }

  // const onDeleteData = async (k) => {
  //   await props.onDeleteApplication(initMock, k);
  //   setDialogStatus(false);
  // }

  // const renderTotalList = () => {
  //   let applicationDetails = props.applicationsData.data ? props.applicationsData.data : [];
  //   return (
  //     <p style={{ display: 'block', fontSize: 14 }}>Showing: {(activePage - 1) * filterParamsData.limits} to {applicationDetails.length > (activePage * filterParamsData.limits) ? activePage * filterParamsData.limits : (activePage - 1) * filterParamsData.limits + applicationDetails.length} of first {props.applicationsData.totalRequests} records (may have more)</p>
  //   );
  // }

  return (
    <React.Fragment>
      <div className="page">
        <PageHeader pageTitle={"FileService Application"} />
        <div className="page__body">
          <div className="ca-application-header">
            {/* <div className="mb-3 ca-application-item-header" id="ca-application-btn-add">
              <Button variant="primary" className="ml-3 mt-3 mb-0" onClick={() => { onOpenCreateModal() }}>Add New</Button>
            </div> */}
            <div className="mb-9 ca-application-item-header" id="ca-application-btn-add">
              <h3>View Image</h3>
              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Application ID</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeAppIdUpload(e)} placeholder="Application ID" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Base Url</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeBaseUrl(e)} placeholder="Base URL" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>API</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeApi(e)} placeholder="API" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <Button className="ml-3 mt-3 mb-0" onClick={() => ViewFSImage()} variant="primary">
                      Submit
                    </Button>
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                {url !== null ? <img class="image" src={url} width="500" height="300"></img> : ""}
              </div>

              <h3>Upload</h3>
              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Application ID</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeAppIdUpload(e)} placeholder="Application ID" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Username</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeUsernameUpload(e)} placeholder="Username" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Base Url</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeBaseUrlUpload(e)} placeholder="Base URL" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>API</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeApiUpload(e)} placeholder="API" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Name</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeNameUpload(e)} placeholder="Video Name" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Folder ID</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeFolderIDUpload(e)} placeholder="Folder ID" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Description</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeDescriptionUpload(e)} placeholder="Description" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Tags</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeTagUpload(e)} placeholder="Tags" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>Is convert Video</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeIsConvertVideoUpload(e)} placeholder="Is Convert Video" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <lable>LifeTime</lable>
                    <input className="form-filter__form-input form-control" maxLength="200" onChange={e => onChangeLifetimeUpload(e)} placeholder="Lifetime" />
                  </div>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <form onSubmit={handleSubmit}>
                    <lable>File</lable>
                    <br></br>
                    <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                    <br></br>
                    <br></br>
                    {/* <button type="submit" disabled={!(file && name)}>
                      Upload File
                    </button> */}
                    <Button className="ml-3 mt-3 mb-0" type="submit" variant="primary">
                      Upload File
                    </Button>
                  </form>
                </span>
              </div>

              <div className="form-filter__item mb-12">
                <span className="form-filter__controls">
                  <div>
                    <h3>Upload Result</h3>
                    <input class="uploadResult" className="form-filter__form-input form-control uploadResult" placeholder="Upload result" />
                  </div>
                </span>
              </div>
            </div>
          </div>
          {/* <FSApplicationList
            {...props}
            filterParamsData={filterParamsData}
            onDelete={k => openDeleteModal(k)}
            onEdit={k => onEditData(k)}></FSApplicationList>
          {props.applicationsData ? (
            <div className="pagination-container" style={{ flexDirection: 'column' }}>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={filterParamsData.limits}
                totalItemsCount={props.applicationsData.totalRequests}
                onChange={pageNumber => onPageChange(pageNumber)}
              />
              {renderTotalList()}
            </div>
          ) : null}
          <FSApplicationModal
            {...props}
            modalShow={editModalStatus}
            modalData={editData}
            modalMode={modalMode}
            onUpdateData={() => onUpdateData()}
            onCreateData={() => onCreateData()}
            onShowEditModal={(status) => setEditModalStatus(status)}
            onSetEditData={(data) => setEditData(data)}
          />
          <FSApplicationDialog
            id={idDelete}
            modalStatus={dialogStatus}
            onDeleteData={(k) => onDeleteData(k)}
            onUpdateModalStatus={status => setDialogStatus(status)}
          /> */}
          {props.loading ? (<Loading />) : ("")}
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Wrapper);
