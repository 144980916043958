import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'

function UserList({ filterParamsData, onDelete, onEdit, ...props }) {
    let initMock = filterParamsData;
    const refreshList = async () => {
        await props.onInitUser(filterParamsData);
    }

    useEffect(() => {
        if (Object.keys(filterParamsData).length === 0) {
            props.onInitUser(initMock);
        } else {
            refreshList();
        }
    }, []);

    let dataList = [];
    if (props.usersData && Array.isArray(props.usersData.dataDetails)) {
        dataList = props.usersData.dataDetails;
    }
    return (
        <React.Fragment>
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="text-center">No.</th>
                            <th className="text-left">User Email</th>
                            <th className="text-left">Role Name</th>
                            <th className="text-center">IsActive</th>
                            <th className="text-left"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.length > 0 ? (dataList.map((data, index) => {
                            return (
                                <tr key={index + 1}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{data?.email}</td>
                                    <td>{data?.roleName}</td>
                                    <td className="text-center">{data?.isActive ? <FontAwesomeIcon icon={FaIcon.faCheck} /> : null}</td>
                                    <td className="text-right table-actions">
                                        <Button title="Edit Application" variant="link" onClick={() => { onEdit(data.id) }}>
                                            <FontAwesomeIcon icon={FaIcon.faEdit} />
                                        </Button>
                                        {/* <Button title="Delele Application" variant="link" onClick={() => { onDelete(data.id) }}>
                                            <FontAwesomeIcon className="text-danger" icon={FaIcon.faTrashAlt} />
                                        </Button> */}
                                    </td>
                                </tr>
                            );
                        })): (
                            <tr>
                                <td className="text-center" colSpan="5">
                                    NO DATA FOUND
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default UserList;